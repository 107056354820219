<template>
  <b-tab
    class="speces-model"
    :title="$t('specifictaionComponent/componentHead')"
  >
    <h3>
      {{ $t("specifictaionComponent/componentHead") }}
    </h3>
    <h2>
      {{
        isNotCompleted
          ? $t("specificationModel/error/allFormMustBeFilled")
          : null
      }}
    </h2>
    <div v-if="!noSpecKeys">
      <b-table :fields="fields" class="tabel" :items="specs" v-if="!isLoading">
        <template #cell(key)="data">
          <span> {{ data.item.name }}</span>
        </template>
        <template #cell(value)="data">
          <div
            :ref="data.item.key"
            :contenteditable="!!globalPermission['spec-values'].update"
            @input="valueChanged(data.item.key)"
          >
            {{ form_data[data.item.key] }}
          </div>
        </template>
      </b-table>
      <b-row
        v-if="!!globalPermission['spec-values'].update"
        class="d-flex justify-content-end"
      >
        <div class="col col-6 d-flex justify-content-end mt-2">
          <b-button variant="primary" class="ml-1" @click="handelSubmit()">{{
            $t("specificationModel/form/save")
          }}</b-button>
        </div>
      </b-row>
    </div>
    <div class="no-spec-keys" v-else>
      {{ $t("g.product_specifiction/noSpecKeysAlrert") }}
    </div>
    <OverlayComponent :isLoading="isLoading" />
  </b-tab>
</template>
<script>
import { BModal, BTable, BRow, BCol, BButton, BTab } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { number } from "echarts/lib/export";
export default {
  components: {
    BModal,
    BTable,
    BRow,
    BCol,
    BButton,
    BTab,
    OverlayComponent,
  },
  props: {
    categoryId: {
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { label: this.$t("specifictionModel/key"), key: "key" },
        { label: this.$t("specifictionModel/value"), key: "value" },
      ],
      specs: [],
      form_data: {},
      isNotCompleted: false,
      noSpecKeys: false,
      isLoading: true,
      category_id: null,
      isEdit: true,
      archive: null,
    };
  },
  beforeMount() {
    this.getSpecsKeys();
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    async getSpecsKeys() {
      try {
        this.category_id = this.categoryId;
        const response = await this.$http.get(
          `vendor/spec-keys?category_id=${this.category_id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.specs = response.data.data;
          this.specs.map((ele) => {
            this.form_data[ele.key] = null;
          });
          await this.getSpecsValues();
        }
      } catch (err) {
        if (err.response.status === 400) {
          this.noSpecKeys = true;
        }
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    async getSpecsValues() {
      this.isLoading = true;
      try {
        if (this.slug) {
          const response = await this.$http.get(
            `vendor/products/${this.slug}/spec-values`
          );

          if (response.status === 200 || response.status === 201) {
            this.archive = {};
            if (response.data.data.length > 0) {
              this.isEdit = true;
              response.data.data.map(({ id, spec_key, value }) => {
                this.archive[spec_key] = value;
                this.specs.map((ele) => {
                  if (ele.key === spec_key) {
                    ele["id"] = id;
                    Object.assign(ele, { value });
                    this.form_data[spec_key] = value;
                  }
                });
              });
            } else {
              this.isEdit = false;
              this.isLoading = false;
            }
          }
        }
      } catch (err) {
        if (err.response.status === 400) {
          this.isEdit = false;
          this.isLoading = false;
        }
      } finally {
        this.isLoading = false;
      }
    },
    valueChanged(key, value) {
      this.form_data[key] = value || this.$refs[key].innerText;
    },
    handelSubmit() {
      if (this.isEdit) {
        this.onEdit();
      } else {
        this.onSubmit();
      }
    },
    async onSubmit() {
      try {
        const formData = new FormData();
        for (const item in this.form_data) {
          formData.append(`${item}`, this.form_data[item]);
        }

        const response = await this.$http.post(
          `vendor/products/${this.slug}/spec-values`,
          formData
        );
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast(
            "success",
            response.data.message,
            response.data.message
          );
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async onEdit() {
      try {
        const updatedKeys = [];
        for (const item in this.form_data) {
          if (this.form_data[item] !== this.archive[item]) {
            updatedKeys.push(...this.specs.filter((ele) => ele.key === item));
          }
        }
        for (const item in updatedKeys) {
          const formData = new FormData();
          formData.append("value", this.form_data[updatedKeys[item].key]);
          formData.append("lang", this.$i18n.locale);
          this.$http
            .post(
              `vendor/products/${this.slug}/spec-values/${updatedKeys[item].id}?_method=put`,
              formData
            )
            .then((res) => {
              this.$helpers.makeToast(
                "success",
                "g.specificationModel/sucess/head",
                "g.specificationModel/sucess/body"
              );
            });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
  },
  watch: {
    categoryId(is) {
      this.category_id = is;
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
