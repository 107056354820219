]
<template>
  <b-tab :title="$t('show_product/tab_title/main_data')">
    <validation-observer ref="addProductFormMain">
      <b-form class="d-flex flex-column" @submit.prevent>
        <b-card class="product-card-container">
          <b-row>
            <!-- <b-col class="mb-2" cols="12" v-if="vendorsData">
              <b-form-group
                :label="$t('add_product/vendor')"
                label-for="Vendor"
              >
                <validation-provider #default="{ errors }" name="Vendor">
                  <b-form-select
                    v-model="form_data.vendor_id"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option
                      v-for="vendor in vendorsData"
                      :key="vendor.id"
                      :value="vendor.id"
                    >
                      {{ vendor.store_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/suffix_name')"
                label-for="Suffix_Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Suffix_Name"
                  rules="required"
                >
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="text"
                    v-model="form_data.suffix_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sku')" label-for="SKU">
                <validation-provider #default="{ errors }" name="SKU">
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="text"
                    v-model="form_data.sku"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/price')" label-for="Price">
                <validation-provider #default="{ errors }" name="Price">
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    v-model="form_data.price"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    step="0.01"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sale')" label-for="Sale">
                <validation-provider #default="{ errors }" name="Sale">
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="number"
                    v-model="form_data.sale"
                    step="0.01"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/quantity')"
                label-for="Quantity"
              >
                <validation-provider #default="{ errors }" name="Quantity">
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="number"
                    v-model="form_data.qty"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <!-- <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('add_product/min_quantity')"
                label-for="Minimum Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Minimum Quantity"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.min_qty"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/max_quantity')"
                label-for="Maximum Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Maximum Quantity"
                  class="w-100"
                >
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="number"
                    v-model="form_data.max_qty"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <!-- min stock   -->
            <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('g.settings/generalSettings/min_stock')"
                label-for="Min stock"
              >
                <validation-provider #default="{ errors }" name="Min stock">
                  <b-form-input
                    :disabled="!!!globalPermission.products.update"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.min_stock"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **min stock   -->

            <b-col class="mb-2" cols="12">
              <!-- Start Input -->
              <div class="product-images-box">
                <product-main-image-component
                  @set-main-image="handelMainImage"
                  :image="productMainImage"
                />
              </div>
              <product-images-component
                :product-slug="productSlug"
                :images="productImages"
                @set-product-images="handelImages"
                @handel-is-main-change="handelIsMain"
              />
              <RecommendationUploadImage
                :dimensions="{ width: 1024, height: 1024 }"
              />
              <!-- End Input -->
            </b-col>

            <b-col cols="12">
              <div class="switches-box">
                <b-form-checkbox
                  :disabled="!!!globalPermission.products.update"
                  v-model="form_data.is_visible"
                  name="check-button"
                  switch
                >
                  {{ $t("add_product/is_visiable") }}
                </b-form-checkbox>

                <b-form-checkbox
                  :disabled="!!!globalPermission.products.update"
                  v-model="form_data.is_has_vat"
                  name="check-button"
                  switch
                />
                {{ $t("g.value_added_tax") }}
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-button
          v-if="!!globalPermission.products.update"
          @click="handleSubmitProduct"
          type="submit"
          variant="primary"
          class="mb-5 align-self-end"
        >
          {{ $t("add_product/save_changes") }}
        </b-button>
      </b-form>
    </validation-observer>
    <overlay-loading :isLoading="overlayLoading" />
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BFormFile,
  BButton,
  BForm,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
import ProductMainImageComponent from "@/components/ProductsComponents/MainDataTabComponent/MainImageComponent/index.vue";
import ProductImagesComponent from "@/components/ProductsComponents/MainDataTabComponent/ProductImagesComponent/index.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  components: {
    ProductMainImageComponent,
    ProductImagesComponent,
    BRow,
    BCol,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormCheckbox,
    BForm,
    OverlayLoading,
    RecommendationUploadImage,
  },
  props: ["handleOverlayLoading"],
  data() {
    return {
      required,
      overlayLoading: false,
      productSlug: null,
      vendorsData: null,
      productImages: [],
      productMainImage: null,
      form_data: {
        vendor_id: null,
        suffix_name: null,
        sku: null,
        max_qty: null,
        min_qty: null,
        qty: null,
        sale: null,
        price: null,
        mainImage: { path: null },
        productImages: [],
        is_visible: true,
        is_has_vat: false,
      },
      maxQtyActive: false,
      addExatraIcon: require("@/assets/images/icons/Lead icon.svg"),
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      addProductImagesIcon: require("@/assets/images/icons/add-product-images.svg"),
      archive: [],
    };
  },
  created() {
    if (this.$route.params.slug) {
      this.productSlug = this.$route.params.slug;
      this.getProductData(this.$route.params.slug);
    }
  },
  methods: {
    async getProductData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/products/${slug}`);
        if (response.status > 299) {
          throw Error();
        }
        this.form_data = {
          vendor_id: response.data.data.vendor?.id,
          suffix_name: response.data.data.suffix_name,
          sku: response.data.data.sku,
          max_qty: response.data.data.max_qty,
          min_qty: response.data.data.min_qty,
          qty: response.data.data.qty,
          sale: +response.data.data.sale ? response.data.data.sale : null,
          price: response.data.data.price,
          is_visible: response.data.data.is_visible,
          min_stock: response.data.data.min_stock,
          is_has_vat: Boolean(response.data.data.is_has_vat),
          productImages: [],
          mainImage: { path: null },
        };
        this.archive = {
          vendor_id: response.data.data.vendor?.id,
          suffix_name: response.data.data.suffix_name,
          sku: response.data.data.sku,
          max_qty: response.data.data.max_qty,
          min_qty: response.data.data.min_qty,
          qty: response.data.data.qty,
          sale: +response.data.data.sale ? response.data.data.sale : null,
          price: response.data.data.price,
          is_visible: response.data.data.is_visible,
          min_stock: response.data.data.min_stock,
          is_has_vat: response.data.data.is_has_vat,
        };
        this.maxQtyActive = this.form_data.max_qty !== null;
        if (response.data.data.images.length > 0) {
          response.data.data.images.map(({ id, path, is_main }) => {
            is_main
              ? (this.productMainImage = { id, path, is_main })
              : this.productImages.push({ id, path, is_main });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },

    async handleSubmitProduct() {
      this.$refs.addProductFormMain.validate().then(async (success) => {
        if (success) {
          try {
            this.overlayLoading = true;
            const data = this.collectData();
            const res = await this.$http.post(
              `vendor/products/${this.productSlug}?_method=put`,
              data
            );
            if (res.status > 299) {
              throw Error();
            } else {
              this.$router.push({ name: "products" });
              this.$helpers.makeToast(
                "success",
                res.data.message,
                res.data.message
              );
            }
          } catch (error) {
            this.$helpers.handleError(error);
          } finally {
            this.overlayLoading = false;
          }
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    collectData() {
      const formData = new FormData();
      let currentIndex = 0;
      for (const item in this.form_data) {
        if (item === "mainImage") {
          if (this.form_data[item].path !== null) {
            this.form_data[item] &&
              formData.append(
                `images[${currentIndex}][file]`,
                this.form_data[item].file
              );
            this.form_data[item] &&
              formData.append(`images[${currentIndex}][is_main]`, 1);
            currentIndex++;
          }
        } else if (item === "productImages") {
          Array.from(this.form_data[item]).forEach((file, index) => {
            formData.append(`images[${currentIndex}][file]`, file.file.file);
            formData.append(`images[${currentIndex}][is_main]`, 0);
            currentIndex++;
          });
        } else if (item === "is_visible" || item === "is_has_vat") {
          formData.append(item, this.form_data[item] ? 1 : 0);
        } else if (item === "sale") {
          if (this.form_data.sale && this.form_data.sale !== "0.00") {
            formData.append(item, this.form_data[item]);
          }
        } else if (item === "max_qty") {
          this.form_data.max_qty && formData.append(item, this.form_data[item]);
        } else if (item === "sku") {
          this.form_data.sku && formData.append(item, this.form_data[item]);
        } else {
          if (this.form_data[item]) {
            formData.append(item, this.form_data[item]);
          }
        }
      }

      formData.append("lang", localStorage.getItem("lang"));
      return formData;
    },
    async deleteImages(imgObj) {
      try {
        if (imgObj.path) {
          const deleteRequest = await this.$http.delete(
            `vendor/image/${imgObj.id}`
          );
          if ([200, 201].includes(deleteRequest.status)) {
            if (imgObj.is_main) {
              this.productMainImage = null;
            } else {
              this.productImages = this.productImages.filter(
                (img) => img.id !== imgObj.id
              );
              this.form_data.productImages = this.productImages;
            }
          }
        } else {
          if (imgObj.is_main) {
            this.productMainImage = null;
          } else {
            this.productImages = this.productImages.filter(
              (img) => img !== imgObj
            );
            this.form_data.productImages = this.productImages;
          }
        }
      } catch (error) {}
    },
    handelMainImage(file) {
      this.form_data.mainImage = file;
    },
    handelImages(files) {
      const combinationArray = [...files];
      combinationArray.forEach((file) => {
        if (file.file) {
          this.form_data.productImages.push({ file: file });
        }
      });
    },
    handelIsMain({ path, id, file }) {
      if (id) {
        this.productImages = this.productImages.filter((img) => img.id !== id);
        this.productImages.push({
          path: this.productMainImage.path,
          id: this.productMainImage.id,
        });
        this.productMainImage = { path, id };
      } else {
        this.productImages = this.productImages.filter(
          (img) => img.path !== path
        );
        this.form_data.productImages = this.form_data.productImages.filter(
          (img) => img.file.path !== path
        );
        this.productMainImage = { path, file };
        this.form_data.mainImage = { path, file };
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
