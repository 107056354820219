var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"title":_vm.$t('show_product/tab_title/main_data')}},[_c('validation-observer',{ref:"addProductFormMain"},[_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"product-card-container"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/suffix_name'),"label-for":"Suffix_Name"}},[_c('validation-provider',{attrs:{"name":"Suffix_Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.suffix_name),callback:function ($$v) {_vm.$set(_vm.form_data, "suffix_name", $$v)},expression:"form_data.suffix_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sku'),"label-for":"SKU"}},[_c('validation-provider',{attrs:{"name":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.sku),callback:function ($$v) {_vm.$set(_vm.form_data, "sku", $$v)},expression:"form_data.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/price'),"label-for":"Price"}},[_c('validation-provider',{attrs:{"name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"state":errors.length > 0 ? false : null,"type":"number","step":"0.01"},model:{value:(_vm.form_data.price),callback:function ($$v) {_vm.$set(_vm.form_data, "price", $$v)},expression:"form_data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sale'),"label-for":"Sale"}},[_c('validation-provider',{attrs:{"name":"Sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"number","step":"0.01","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.sale),callback:function ($$v) {_vm.$set(_vm.form_data, "sale", $$v)},expression:"form_data.sale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/quantity'),"label-for":"Quantity"}},[_c('validation-provider',{attrs:{"name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.qty),callback:function ($$v) {_vm.$set(_vm.form_data, "qty", $$v)},expression:"form_data.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/max_quantity'),"label-for":"Maximum Quantity"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Maximum Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.max_qty),callback:function ($$v) {_vm.$set(_vm.form_data, "max_qty", $$v)},expression:"form_data.max_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/min_stock'),"label-for":"Min stock"}},[_c('validation-provider',{attrs:{"name":"Min stock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.min_stock),callback:function ($$v) {_vm.$set(_vm.form_data, "min_stock", $$v)},expression:"form_data.min_stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"product-images-box"},[_c('product-main-image-component',{attrs:{"image":_vm.productMainImage},on:{"set-main-image":_vm.handelMainImage}})],1),_c('product-images-component',{attrs:{"product-slug":_vm.productSlug,"images":_vm.productImages},on:{"set-product-images":_vm.handelImages,"handel-is-main-change":_vm.handelIsMain}}),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1024, height: 1024 }}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"switches-box"},[_c('b-form-checkbox',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"name":"check-button","switch":""},model:{value:(_vm.form_data.is_visible),callback:function ($$v) {_vm.$set(_vm.form_data, "is_visible", $$v)},expression:"form_data.is_visible"}},[_vm._v(" "+_vm._s(_vm.$t("add_product/is_visiable"))+" ")]),_c('b-form-checkbox',{attrs:{"disabled":!!!_vm.globalPermission.products.update,"name":"check-button","switch":""},model:{value:(_vm.form_data.is_has_vat),callback:function ($$v) {_vm.$set(_vm.form_data, "is_has_vat", $$v)},expression:"form_data.is_has_vat"}}),_vm._v(" "+_vm._s(_vm.$t("g.value_added_tax"))+" ")],1)])],1)],1),(!!_vm.globalPermission.products.update)?_c('b-button',{staticClass:"mb-5 align-self-end",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.handleSubmitProduct}},[_vm._v(" "+_vm._s(_vm.$t("add_product/save_changes"))+" ")]):_vm._e()],1)],1),_c('overlay-loading',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }