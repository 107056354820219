<template>
  <div class="show-product">
    <catalog-data-card v-if="catalogData" :selectedCatalogProp="catalogData" />
    <b-card>
      <b-tabs v-model="activeIndex" pills>
        <dashboard-component :productData="productData" />
        <main-data-tab-component
          v-if="
            globalPermission['products'] && globalPermission['products'].index
          "
        />
        <!-- <extra-model /> -->
        <specification-model
          :category-id="productData.category_id"
          v-if="
            globalPermission['spec-keys'] && globalPermission['spec-keys'].index
          "
        />
        <tags-tab-component
          v-if="globalPermission['tags'] && globalPermission['tags'].index"
        />
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ExtraModel from "@/components/ProductsComponents/ExtraModel";
import SpecificationModel from "@/components/ProductsComponents/SpecificationModel";
import { BTab, BTabs, BCardText, BCard } from "bootstrap-vue";
import CatalogDataCard from "@/components/ProductsComponents/CatalogDataCard/index.vue";
import TagsTabComponent from "@/components/ProductsComponents/TagsTabComponent/index.vue";
import DashboardComponent from "@/components/ProductsComponents/DashboardComponent/index.vue";
import MainDataTabComponent from "@/components/ProductsComponents/MainDataTabComponent/index.vue";

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BCardText,
    CatalogDataCard,
    TagsTabComponent,
    DashboardComponent,
    MainDataTabComponent,
    SpecificationModel,
    ExtraModel,
  },
  data() {
    return {
      catalogData: null,
      categoryId: null,
      productData: null,
      activeIndex: 0,
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.activeIndex = 2;
    }
    if (this.$route.params.catalogSlug) {
      this.getCatalogData(this.$route.params.catalogSlug);
    }
    if (this.$route.params.slug) {
      this.getProductData(this.$route.params.slug);
    }
  },
  methods: {
    async getCatalogData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/catalogs/${slug}`);
        if (response.status === 200 || response.status === 201) {
          this.catalogData = response.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async getProductData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/products/${slug}`);
        if (response.status === 200 || response.status === 201) {
          this.productData = response.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
