<template>
  <b-tab :title="$t('show_product/tab_title/dashboard')">
    <b-card class="dashboard-component">
      <b-row :class="{full:(productData&&productData.is_completed ) }">
        <b-col  class="mb-2" cols="12" v-if="productData&&productData.is_completed">
          <rating :rate_data="productData.rates"  />
        </b-col>

        <b-col v-if="productData" cols="12" class="mb-2 product-info-container">
          <hr  v-if="productData&&productData.is_completed " />
          <div class="product-info-box">
            <div class="info-item">
              <img :src="inStockIcon" />
              <div class="item-title">
                <h4>{{ $t("show_product/dashboard_tab/in_stock") }}</h4>
                <p>{{ productData.qty }}</p>
              </div>
            </div>
            <div class="info-item">
              <img :src="soldIcon" />
              <div class="item-title">
                <h4>{{ $t("show_product/dashboard_tab/sold") }}</h4>
                <p>{{ productData.order_count }}</p>
              </div>
            </div>
          </div>
          <div class="product-info-box">
            <div class="info-item">
              <img :src="favIcon" />
              <div class="item-title">
                <h4>{{ $t("show_product/dashboard_tab/fav") }}</h4>
                <p>{{ productData.favorite_count }}</p>
              </div>
            </div>
            <div class="info-item">
              <img :src="viewsIcon" />
              <div class="item-title">
                <h4>{{ $t("show_product/dashboard_tab/view") }}</h4>
                <p>{{ productData.view_count }}</p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-tab>
</template>

<script>
import { BRow, BCol, BCard, BTab } from "bootstrap-vue";
import Rating from "@/components/ProductsComponents/DashboardComponent/Rating/Rating.vue";
export default {
  name: "DashboardComponent",
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    Rating,
  },
  props: ["productData"],
  data() {
    return {
      inStockIcon: require("@/assets/images/icons/store-3-fill.svg"),
      soldIcon: require("@/assets/images/icons/shopping-basket-fill.svg"),
      favIcon: require("@/assets/images/icons/heart-3-fill.svg"),
      viewsIcon: require("@/assets/images/icons/eye-fill.svg"),
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./index";
</style>
