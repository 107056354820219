<template>
  <b-tab :title="$t('show_product/tab_title/Tags')">
    <validation-observer ref="addProductFormTags">
      <b-form
        class="d-flex flex-column"
        @submit.prevent="handleSubmitProductTags"
      >
        <b-card class="tags-component">
          <b-row>
            <b-col class="mb-2" cols="12">
              <!-- Start Dropdown -->
              <b-form-group :label="$t('add_product/tags')" label-for="Tags">
                <validation-provider #default="{ errors }" name="Tags">
                  <div>
                    <multiselect
                      v-model="value"
                      :tag-placeholder="$t('add_product/select_this_tag')"
                      :placeholder="$t('add_product/search_to_add_tag')"
                      label="name"
                      track-by="name"
                      :options="tagsData"
                      :multiple="true"
                      :taggable="true"
                    ></multiselect>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>

            <b-col
              v-if="!!globalPermission.products.update"
              class="mb-2"
              cols="12"
            >
              <!-- Start Dropdown -->
              <b-button
                type="submit"
                variant="primary"
                class="mb-5 align-self-end"
              >
                {{ $t("add_product/save") }}
              </b-button>
              <!-- End Dropdown -->
            </b-col>
          </b-row>
        </b-card>
        <overlay-loading :isLoading="overlayLoading" />
      </b-form>
    </validation-observer>
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BTab,
  BFormGroup,
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
export default {
  name: "TagsTabComponent",
  components: {
    BTab,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    BForm,
    OverlayLoading,
  },
  data() {
    return {
      productSlug: null,
      tagsData: [],
      selectedTags: [],
      overlayLoading: false,
      value: [],
      product_id: null,
    };
  },
  created() {
    this.getTagsData();
    if (this.$route.params.slug) {
      this.productSlug = this.$route.params.slug;
      this.getProductData(this.$route.params.slug);
    }
  },
  methods: {
    async getTagsData() {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/tags`);
        if (response.status === 200 || response.status === 201) {
          this.tagsData = response.data.data;
        }
      } catch (error) {
      } finally {
        this.overlayLoading = false;
      }
    },
    async getProductData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/products/${slug}`);
        if (response.status === 200 || response.status === 201) {
          this.product_id = response.data.data.id;

          response.data.data.tags.length > 0 &&
            response.data.data.tags.map((item) => {
              this.value.push({ name: item.name, id: item.id });
            });
        } else {
        }
      } catch (error) {
      } finally {
        this.overlayLoading = false;
      }
    },
    async handleSubmitProductTags() {
      try {
        this.overlayLoading = true;
        const data = this.collectData();
        const response = await this.$http.post(
          `vendor/products/${this.productSlug}?_method=put`,
          data
        );
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast(
            "success",
            response.data.message,
            response.data.message
          );
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    collectData() {
      const formData = new FormData();
      if (this.value.length > 0) {
        this.value.map((tag, index) => {
          formData.append(`tags[${index}]`, tag["id"]);
        });
        return formData;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "./index";
</style>
